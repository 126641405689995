import {EventEmitter, Injectable, signal, WritableSignal} from '@angular/core';
import {UserPromptResponse} from "../interfaces/user-prompt-response";
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {ErrorService} from "./error.service";
import {ChatMessage} from "../interfaces/chat_message";
import {ChatRole} from "../interfaces/ChatRole";
import {UserPromptResponseMeta} from "../interfaces/user-prompt-response-meta";
import {WhitelabelService} from "./whitelabel.service";


import {Router} from "@angular/router";
import {v4 as uuidv4} from "uuid";

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  easyLanguage = false;

  latestResponseMeta: UserPromptResponseMeta = {
    client_request_uuid: "",
    chat_uuid: "",
    server_request_uuid: "",
    document_references: [],
    search_only: false,
    is_completed: true,
    limit_reached: false
  };

  _latestResponse: ChatMessage = {
    content: "",
    role: ChatRole.ASSISTANT,
    meta: this.latestResponseMeta
  };

  defaultGreetingMeta: UserPromptResponseMeta = {
    client_request_uuid: "",
    chat_uuid: uuidv4(),
    server_request_uuid: "",
    document_references: [],
    search_only: false,
    is_completed: true,
    limit_reached: false
  };


  defaultGreetingChat: ChatMessage = {
    content: this.whitelabelService.description,
    role: ChatRole.SYSTEM,
    meta: this.defaultGreetingMeta
  }

  _chatHistory: ChatMessage[] = [this.defaultGreetingChat]

  promptRequestSignal: WritableSignal<ChatMessage | undefined> = signal(undefined);
  chatHistorySignal: WritableSignal<ChatMessage[]> = signal(this._chatHistory);
  displayProgressBar: WritableSignal<boolean> = signal(false);
  newQuestion: EventEmitter<void> = new EventEmitter();


  private backendUrl: string = environment.backendUrl;
  private backendEndpoint: string = this.backendUrl + "/prompt";

  private ws?: WebSocket = undefined;
  private websocketReconnectTimeout: any = null
  private websocketReconnectInProgress?: Promise<void> = undefined

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private whitelabelService: WhitelabelService,
    private router: Router,
  ) {
    this.reconnectWebSocket().catch(e => {
      console.warn('Initial WebService setup failed')
    })
    //this.displayProgressBar.set(true);
  }

  async resetChat() {
    this._chatHistory = [this.defaultGreetingChat]
    this.chatHistorySignal.set(this._chatHistory);
    if (this.router.routerState.snapshot.url !== '/') {
      await this.router.navigate(['/'])
    }
  }

  async makeServerCall(prompt: ChatMessage) {
    // let the feedback component know that there was a new question so that it can prepare to take new feedback
    this.newQuestion.emit();

    this.promptRequestSignal.set(prompt);
    this._chatHistory.push(prompt)

    //display progress bar while the answer is fetched
    this.displayProgressBar.set(true);


    let userPromptResponseMeta: UserPromptResponseMeta = {
      client_request_uuid: "",
      chat_uuid: "",
      document_references: [],
      search_only: this.promptRequestSignal()?.meta['search_only'],
      is_completed: false,
      limit_reached: false
    }

    try {
      // Ensure WebSocket is connected
      await this.reconnectWebSocket();

      if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
        throw new Error("WebSocket not connected");
      }

      const startTime = Date.now();

      // Set a timeout for server response
      const waitForResponseTimeout = setTimeout(() => {
        this.errorService.showError(
          $localize`Leider konnte der Server diese Anfrage aktuell nicht beantworten. Bitte versuche es später noch einmal.`,
          new Error("Response timeout")
        );
        this.displayProgressBar.set(false);
      }, 60000);

      // Handle incoming messages
      this.ws.onmessage = (event) => {
        clearTimeout(waitForResponseTimeout);


        let incomingChat = JSON.parse(event.data)
        let responseMeta = incomingChat.meta

        if (responseMeta.client_request_uuid === prompt.meta["client_request_uuid"]) {

          clearTimeout(waitForResponseTimeout)

          if (responseMeta.limit_reached) {
            console.warn("Limit reached for request " + responseMeta.client_request_uuid)
            this.errorService.openErrorDialog($localize`Leider ist ihr Kontingent aktuell ausgeschöpft. Bitte versuche es später noch einmal.`, undefined)
            this.displayProgressBar.set(false);

          } else { // process the chat message and store correctly

            if (responseMeta.is_completed) {
              let endTime = new Date().valueOf()
              let duration = endTime - startTime
              console.log("Duration for request " + responseMeta.client_request_uuid + ": ", (duration / 1000).toFixed(3) + "s")
            }

            userPromptResponseMeta = {
              client_request_uuid: responseMeta.client_request_uuid,
              server_request_uuid: responseMeta.server_request_uuid,
              chat_uuid: responseMeta.chat_uuid,
              document_references: responseMeta.document_references,
              search_only: responseMeta.search_only,
              is_completed: responseMeta.is_completed,
              limit_reached: responseMeta.limit_reached
            };

            let latestChunk: ChatMessage = {
              content: incomingChat.content,
              role: incomingChat.role,
              meta: userPromptResponseMeta
            }

            this._chatHistory.push(latestChunk)
            this.chatHistorySignal.set(this._chatHistory); //set signal; this change is detected in the answer-display component
            this.displayProgressBar.set(false); //stop progress bar when answer is fetched
            this.newQuestion.emit(); // let the feedback component know that there was a new question so that it can prepare to take new feedback
          }
        } else {
          console.log('Something went wrong, the UUIDs don\'t match. Expected: ' + responseMeta.client_request_uuid + " received instead: " + prompt.meta["client_request_uuid"])
        }
        this._latestResponse = this._chatHistory[this._chatHistory.length - 1]
      };


      // Send chat history
      this.ws.send(JSON.stringify(this._chatHistory));
      console.log("Chat history sent successfully.");
    } catch (error) {
      console.error("Error in WebSocket operation:", error);
      this.errorService.showError(
        $localize`Leider konnte der Server diese Anfrage aktuell nicht beantworten. Bitte versuche es später noch einmal.`,
        error instanceof Error ? error : new Error("Unknown error")
      );
      this.displayProgressBar.set(false);
    }
  }

  async reconnectWebSocket() {
    const retryInterval = 5000; // 5 seconds interval

    // make sure a pending reconnect is not interrupted
    if (this.websocketReconnectInProgress) {
      await this.websocketReconnectInProgress.catch(() => {})
    }

    let willCreateNewWebSocket = false
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      willCreateNewWebSocket = true
      let wsUrl = this.backendUrl.replace("http", "ws")
      if (!wsUrl.startsWith("ws")) {
        let webSocketProtocol = window.location.protocol == 'https:' ? 'wss' : 'ws'
        wsUrl = webSocketProtocol + "://" + window.location.host + wsUrl
      }
      console.log("Connecting WebSocket ...")
      const awaitConnectStartTime = Date.now();
      this.ws = new WebSocket(wsUrl + "/ws")
      this.ws.onopen = () => {
        clearTimeout(this.websocketReconnectTimeout)
        const duration = (Date.now() - awaitConnectStartTime) / 1000;
        console.log(`WebSocket connected after ${duration.toFixed(3)}s`);

      }
      this.ws.onerror = (e) => {
        console.warn(e)
        this.websocketReconnectTimeout = setTimeout(() => {
          this.reconnectWebSocket().catch(e => {
            // console.warn(e)
          })
        }, retryInterval)
      }
    }

    let promise = new Promise<void>((resolve, reject) => {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        resolve()
      } else {
        if (this.ws) {
          this.ws.addEventListener("open", (event) => {
            resolve()
          });
          addEventListener("error", (event) => {
            reject()
          });
          setTimeout(() => {
            reject()
          }, 5000)
        } else {
          reject()
        }
      }
    })

    if (willCreateNewWebSocket) {
      this.websocketReconnectInProgress = promise;
    }

    return promise;
  }

}
